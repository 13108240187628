<template>
  <div class="v-stack gap-3 h-stretch">
    <div ref="overlay" class="overlay">
      <div class="card dynamic">
        <div class="content mt-3">
          <div class="v-stack h-stretch gap-5">
            <div>Are you sure you want to delete this expense?</div>
          </div>
        </div>
        <div class="footer gap-3 h-space-between mt-3">
          <button @click="dismiss()" class="submit">Cancel</button>
          <button @click="remove()" class="edit">Delete</button>
        </div>
      </div>
    </div>

    <div v-if="expense" class="card light">
      <div class="content h-stretch">
        <div class="v-stack gap-3 h-stretch">
          <label class="text-left required">Title</label>
          <input v-model="expense.name" type="text" />
          <label class="text-left required">Amount</label>
          <input v-model="expense.amount" type="number" />
          <label class="text-left">Description</label>
          <textarea v-model="expense.description" />
          <div class="h-stack h-space-between">
            <button @click="removeDialog()" class="edit">Delete</button>
            <button @click="submit()" class="submit">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["id"],
  data() {
    return {
      expense: null,
    };
  },
  methods: {
    ...mapActions(["getExpense", "updateExpense", "removeExpense"]),
    submit() {
      this.updateExpense(this.expense)
        .then(() => {
          this.$router.go(-1);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    removeDialog() {
      this.$refs.overlay.classList.add("show");
    },
    dismiss() {
      this.$refs.overlay.classList.remove("show");
    },
    remove() {
      this.removeExpense(this.id)
        .then(() => {
          this.$router.go(-1);
        })
        .catch((error) => {
          console.log(error);
        });
      this.dismiss();
    },
  },
  mounted() {
    this.getExpense(this.id)
      .then((expense) => {
        this.expense = expense;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>